.pointer-events-none {
	pointer-events: none;
}
.cursor-pointer {
	cursor: pointer;
}
.cursor-default {
	cursor: default;
}
.disable-user-select {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.outline-none {
  	outline: 0;
}
