
.card-wrap {
  transition: background $transition-time ease-in-out;
  background: transparent linear-gradient(180deg, #FFFFFF 0%, #FFFFFF00 100%);
  .rgba-bg-orange-10 {
    transition: all $transition-time ease-in-out ;
  }
  &:hover {
    background: #FFFFFF;
    background: transparent linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 100%);
    .more {
      display: block;
    }
    .rgba-bg-orange-10 {
      background-color: $orange !important;
      color:$white !important;
    }
  }
  .more {
    display: none;
  }
}
.rgba-bg-orange-10 {
  transition: all $transition-time ease-in-out ;
  &:hover {
    background-color: $orange !important;
    color:$white !important;
  }
}