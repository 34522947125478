.menu-list {
    position: fixed;
    min-height: 100vh;
    width: 55%;
    height: 100%;
    z-index: 9999;
    background-color:$white;
    top: 0;
    right: -100%;
    overflow-y: auto;
    overflow-x: hidden;
    transition:.5s cubic-bezier(0.55, 0.085, 0.68, 0.53);
    &::-webkit-scrollbar {
		width: 4px;
        height: 48px;
		display:block;
	  }
	  &::-webkit-scrollbar-thumb {
		background-color: #d7dce4;
		border-radius: 6px;
	  }
    @media (min-width:1600px) {
        width: 968px;
    }
    @media (max-width:767px) {
        width: 100%;
    }
    &.active {
        right: 0%;
    }
    li {
        a {
            -webkit-text-stroke: 2px $black;
            color: transparent;
            &:hover {
                -webkit-text-stroke: 2px transparent;
                color: $black;
            }
        }
    }
}

#globalContainer {
    &::after {
        // content: '';
        // position: fixed;
        // width: 100%;
        // height: 100%;
        // left: 0;
        // top: 0;
        // z-index: 1;
        // background-color: rgba($color: #000000, $alpha: 0.7);
        // opacity: 0;
        // z-index: -1;
        // transition: .4s ease-in-out;
    }
    .backdropburger {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba($color: #000000, $alpha: .5);
        transition: .5s ease-in-out;
        opacity: 0;
        pointer-events: none;
        
    }
    &.menu-open {
        overflow: hidden;
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        &::after {
            // opacity: 1;
            // z-index: 1;
        }
        .backdropburger {
            opacity: 1;
            pointer-events: all;
        }
    }
}
.burger-menu {
    .burger-line {
        transition: .3s ease-in-out;
        transform-origin: right;
    }
    &:hover {
        .burger-line  {
            &:nth-child(2) {
                transform: scaleX(.7);
            }
        }
    }
}
