@font-face {
  font-family: 'Helvetica Neue LT GEO 55 Roman';
  src: url('../../../fonts/helvetica-neue-lt-geo-55-roman/helvetica-neue-lt-geo-55-roman.eot');
  src: url('../../../fonts/helvetica-neue-lt-geo-55-roman/helvetica-neue-lt-geo-55-roman.eot?#iefix')
      format('embedded-opentype'),
    url('../../../fonts/helvetica-neue-lt-geo-55-roman/helvetica-neue-lt-geo-55-roman.woff2')
      format('woff2'),
    url('../../../fonts/helvetica-neue-lt-geo-55-roman/helvetica-neue-lt-geo-55-roman.woff')
      format('woff'),
    url('../../../fonts/helvetica-neue-lt-geo-55-roman/helvetica-neue-lt-geo-55-roman.ttf')
      format('truetype'),
    url('../../../fonts/helvetica-neue-lt-geo-55-roman/helvetica-neue-lt-geo-55-roman.svg#helvetica-neue-lt-geo-55-roman')
      format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue LT GEO 55 Roman Caps';
  src: url('../../../fonts/helvetica-neue-lt-geo-55-roman-caps/helvetica-neue-lt-geo-55-roman-caps.eot');
  src: url('../../../fonts/helvetica-neue-lt-geo-55-roman-caps/helvetica-neue-lt-geo-55-roman-caps.eot?#iefix')
      format('embedded-opentype'),
    url('../../../fonts/helvetica-neue-lt-geo-55-roman-caps/helvetica-neue-lt-geo-55-roman-caps.woff2')
      format('woff2'),
    url('../../../fonts/helvetica-neue-lt-geo-55-roman-caps/helvetica-neue-lt-geo-55-roman-caps.woff')
      format('woff'),
    url('../../../fonts/helvetica-neue-lt-geo-55-roman-caps/helvetica-neue-lt-geo-55-roman-caps.ttf')
      format('truetype'),
    url('../../../fonts/helvetica-neue-lt-geo-55-roman-caps/helvetica-neue-lt-geo-55-roman-caps.svg#helvetica-neue-lt-geo-55-roman-caps')
      format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue LT GEO 65 Medium';
  src: url('../../../fonts/helvetica-neue-lt-geo-65-medium/helvetica-neue-lt-geo-65-medium.eot');
  src: url('../../../fonts/helvetica-neue-lt-geo-65-medium/helvetica-neue-lt-geo-65-medium.eot?#iefix')
      format('embedded-opentype'),
    url('../../../fonts/helvetica-neue-lt-geo-65-medium/helvetica-neue-lt-geo-65-medium.woff2')
      format('woff2'),
    url('../../../fonts/helvetica-neue-lt-geo-65-medium/helvetica-neue-lt-geo-65-medium.woff')
      format('woff'),
    url('../../../fonts/helvetica-neue-lt-geo-65-medium/helvetica-neue-lt-geo-65-medium.ttf')
      format('truetype'),
    url('../../../fonts/helvetica-neue-lt-geo-65-medium/helvetica-neue-lt-geo-65-medium.svg#helvetica-neue-lt-geo-65-medium')
      format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue LT GEO 65 Medium Caps';
  src: url('../../../fonts/helvetica-neue-lt-geo-65-medium-caps/helvetica-neue-lt-geo-65-medium-caps.eot');
  src: url('../../../fonts/helvetica-neue-lt-geo-65-medium-caps/helvetica-neue-lt-geo-65-medium-caps.eot?#iefix')
      format('embedded-opentype'),
    url('../../../fonts/helvetica-neue-lt-geo-65-medium-caps/helvetica-neue-lt-geo-65-medium-caps.woff2')
      format('woff2'),
    url('../../../fonts/helvetica-neue-lt-geo-65-medium-caps/helvetica-neue-lt-geo-65-medium-caps.woff')
      format('woff'),
    url('../../../fonts/helvetica-neue-lt-geo-65-medium-caps/helvetica-neue-lt-geo-65-medium-caps.ttf')
      format('truetype'),
    url('../../../fonts/helvetica-neue-lt-geo-65-medium-caps/helvetica-neue-lt-geo-65-medium-caps.svg#helvetica-neue-lt-geo-65-medium-caps')
      format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue LT GEO 75 Bold';
  src: url('../../../fonts/helvetica-neue-lt-geo-75-bold/helvetica-neue-lt-geo-75-bold.eot');
  src: url('../../../fonts/helvetica-neue-lt-geo-75-bold/helvetica-neue-lt-geo-75-bold.eot?#iefix')
      format('embedded-opentype'),
    url('../../../fonts/helvetica-neue-lt-geo-75-bold/helvetica-neue-lt-geo-75-bold.woff2')
      format('woff2'),
    url('../../../fonts/helvetica-neue-lt-geo-75-bold/helvetica-neue-lt-geo-75-bold.woff')
      format('woff'),
    url('../../../fonts/helvetica-neue-lt-geo-75-bold/helvetica-neue-lt-geo-75-bold.ttf')
      format('truetype'),
    url('../../../fonts/helvetica-neue-lt-geo-75-bold/helvetica-neue-lt-geo-75-bold.svg#helvetica-neue-lt-geo-75-bold')
      format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue LT GEO 75 Bold Caps';
  src: url('../../../fonts/helvetica-neue-lt-geo-75-bold-caps/helvetica-neue-lt-geo-75-bold-caps.eot');
  src: url('../../../fonts/helvetica-neue-lt-geo-75-bold-caps/helvetica-neue-lt-geo-75-bold-caps.eot?#iefix')
      format('embedded-opentype'),
    url('../../../fonts/helvetica-neue-lt-geo-75-bold-caps/helvetica-neue-lt-geo-75-bold-caps.woff2')
      format('woff2'),
    url('../../../fonts/helvetica-neue-lt-geo-75-bold-caps/helvetica-neue-lt-geo-75-bold-caps.woff')
      format('woff'),
    url('../../../fonts/helvetica-neue-lt-geo-75-bold-caps/helvetica-neue-lt-geo-75-bold-caps.ttf')
      format('truetype'),
    url('../../../fonts/helvetica-neue-lt-geo-75-bold-caps/helvetica-neue-lt-geo-75-bold-caps.svg#helvetica-neue-lt-geo-75-bold-caps')
      format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Helvetica Neue LT GEO 85 Heavy';
  src: url('../../../fonts/helvetica-neue-lt-geo-85-heavy/Helvetica Neue LT GEO 85 Heavy.eot');
  src: url('../../../fonts/helvetica-neue-lt-geo-85-heavy/Helvetica Neue LT GEO 85 Heavy.eot?#iefix') format('embedded-opentype'),
       url('../../../fonts/helvetica-neue-lt-geo-85-heavy/Helvetica Neue LT GEO 85 Heavy.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueLTGEO-85Heavy';
  src: url('../../../fonts/helvetica-neue-lt-geo-85-heavy/HelveticaNeueLTGEO-85Heavy.svg#HelveticaNeueLTGEO-85Heavy') format('svg'),
       url('../../../fonts/helvetica-neue-lt-geo-85-heavy/HelveticaNeueLTGEO-85Heavy.ttf') format('truetype'),
       url('../../../fonts/helvetica-neue-lt-geo-85-heavy/HelveticaNeueLTGEO-85Heavy.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'FiraGO-Italic';
  src: url('../../../fonts/firago/FiraGO-Italic.eot');
  src: url('../../../fonts/firago/FiraGO-Italic.eot?#iefix') format('embedded-opentype'),
       url('../../../fonts/firago/FiraGO-Italic.svg#FiraGO-Italic') format('svg'),
       url('../../../fonts/firago/FiraGO-Italic.ttf') format('truetype'),
       url('../../../fonts/firago/FiraGO-Italic.woff') format('woff'),
       url('../../../fonts/firago/FiraGO-Italic.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'FiraGO-MediumItalic';
  src: url('../../../fonts/firago-medium/FiraGO-MediumItalic.eot');
  src: url('../../../fonts/firago-medium/FiraGO-MediumItalic.eot?#iefix') format('embedded-opentype'),
       url('../../../fonts/firago-medium/FiraGO-MediumItalic.svg#FiraGO-MediumItalic') format('svg'),
       url('../../../fonts/firago-medium/FiraGO-MediumItalic.ttf') format('truetype'),
       url('../../../fonts/firago-medium/FiraGO-MediumItalic.woff') format('woff'),
       url('../../../fonts/firago-medium/FiraGO-MediumItalic.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'Helvetica Neue LT GEO 95 Black';
  src: url('../../../fonts/Helvetica-black/Helvetica Neue LT GEO 95 Black.eot');
  src: url('../../../fonts/Helvetica-black/Helvetica Neue LT GEO 95 Black.eot?#iefix') format('embedded-opentype'),
       url('../../../fonts/Helvetica-black/Helvetica Neue LT GEO 95 Black.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'HelveticaNeueLTGEO-95Black';
  src: url('../../../fonts/Helvetica-black/HelveticaNeueLTGEO-95Black.svg#HelveticaNeueLTGEO-95Black') format('svg'),
       url('../../../fonts/Helvetica-black/HelveticaNeueLTGEO-95Black.ttf') format('truetype'),
       url('../../../fonts/Helvetica-black/HelveticaNeueLTGEO-95Black.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ArcticFox-Bold';
  src: url('../../../fonts/arctic-bold/ArcticFox-Bold.eot');
  src: url('../../../fonts/arctic-bold/ArcticFox-Bold.eot?#iefix') format('embedded-opentype'),
       url('../../../fonts/arctic-bold/ArcticFox-Bold.svg#ArcticFox-Bold') format('svg'),
       url('../../../fonts/arctic-bold/ArcticFox-Bold.ttf') format('truetype'),
       url('../../../fonts/arctic-bold/ArcticFox-Bold.woff') format('woff'),
       url('../../../fonts/arctic-bold/ArcticFox-Bold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'ArcticFox-Headline';
  src: url('../../../fonts/arctic-headline/ArcticFox-Headline.eot');
  src: url('../../../fonts/arctic-headline/ArcticFox-Headline.eot?#iefix') format('embedded-opentype'),
       url('../../../fonts/arctic-headline/ArcticFox-Headline.svg#ArcticFox-Headline') format('svg'),
       url('../../../fonts/arctic-headline/ArcticFox-Headline.ttf') format('truetype'),
       url('../../../fonts/arctic-headline/ArcticFox-Headline.woff') format('woff'),
       url('../../../fonts/arctic-headline/ArcticFox-Headline.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}





// ru fonts
@font-face {
	font-family: 'Helvetica-base';
	src: url('../../../fonts/ru/base/HelveticaNeueCyr-Roman.eot');
	src: local('../../../fonts/ru/base/HelveticaNeueCyr-Roman'),
		url('../../../fonts/ru/base/HelveticaNeueCyr-Roman.eot?#iefix') format('embedded-opentype'),
		url('../../../fonts/ru/base/HelveticaNeueCyr-Roman.woff') format('woff'),
		url('../../../fonts/ru/base/HelveticaNeueCyr-Roman.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Helvetica-medium';
	src: url('../../../fonts/ru/medium/HelveticaNeueCyr-Medium.eot');
	src: local('../../../fonts/ru/medium/HelveticaNeueCyr-Medium'),
		url('../../../fonts/ru/medium/HelveticaNeueCyr-Medium.eot?#iefix') format('embedded-opentype'),
		url('../../../fonts/ru/medium/HelveticaNeueCyr-Medium.woff') format('woff'),
		url('../../../fonts/ru/medium/HelveticaNeueCyr-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family: 'Helvetica-bold';
	src: url('../../../fonts/ru/bold/HelveticaNeueCyr-Bold.eot');
	src: local('../../../fonts/ru/bold/HelveticaNeueCyr-Bold'),
		url('../../../fonts/ru/bold/HelveticaNeueCyr-Bold.eot?#iefix') format('embedded-opentype'),
		url('../../../fonts/ru/bold/HelveticaNeueCyr-Bold.woff') format('woff'),
		url('../../../fonts/ru/bold/HelveticaNeueCyr-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

// 
@font-face {
    font-family: 'Lari';
    src: url('../../../fonts/lari/lari.eot');
    src: url('../../../fonts/lari/lari.eot') format('embedded-opentype'),
         url('../../../fonts/lari/lari.woff') format('woff'),
         url('../../../fonts/lari/lari.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
  font-family: 'AvenirNextGeorgian-Regular';
  src: url('../../../fonts/AvenirNextGeorgian-Regular.eot');
  src: url('../../../fonts/AvenirNextGeorgian-Regular.eot?#iefix') format('embedded-opentype'),
       url('../../../fonts/AvenirNextGeorgian-Regular.svg#AvenirNextGeorgian-Regular') format('svg'),
       url('../../../fonts/AvenirNextGeorgian-Regular.ttf') format('truetype'),
       url('../../../fonts/AvenirNextGeorgian-Regular.woff') format('woff'),
       url('../../../fonts/AvenirNextGeorgian-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}


$font-size: () !default;
$font-size: map-merge(
    ( 6:  $font-6,
      10: $font-10,
      11: $font-11,
      12: $font-12,
      13: $font-13,
      14: $font-14,
      15: $font-15,
      16: $font-16,
      18: $font-18,
      21: $font-21,
      23: $font-23,
      24: $font-24,
      26: $font-26,
      28: $font-28,
      30: $font-30,
      32: $font-32,
      34: $font-34,
      36: $font-36,
      38: $font-38,
      45: $font-45,
      48: $font-48,
      64: $font-64,
      72: $font-72,
      96: $font-96,
      144:$font-144,
      167:$font-167,
    ),
    $font-size
);

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $name, $value in $font-size {
      .font-size#{$infix}-#{$name} {
        font-size: $value !important;
      }
    }
    @each $name, $value in $font-size {
      .line-height#{$infix}-#{$name} {
        line-height: $value + 10!important;
      }
    }
  }
}
.is-uppercase {
  text-transform: uppercase;
-moz-font-feature-settings: 'case';
-webkit-font-feature-settings: 'case';
font-feature-settings: 'case' on;
}
.font-base {
  font-family: 'AvenirNextGeorgian-Regular';
}
.font-base-heavy {
  font-family: 'AvenirNextGeorgian-Regular';
}
.font-black {
  font-family: 'AvenirNextGeorgian-Regular';
}
.font-base-caps {
  font-family: 'AvenirNextGeorgian-Regular';
    @extend .is-uppercase;
}
.font-medium {
  font-family: 'AvenirNextGeorgian-Regular';
}
.font-medium-caps {
  font-family: 'AvenirNextGeorgian-Regular';
    @extend .is-uppercase;
}
.font-bold {
  font-family: 'AvenirNextGeorgian-Regular';
}
.font-bold-caps {
  font-family: 'AvenirNextGeorgian-Regular';
    @extend .is-uppercase;
}
.font-gel {
  font-family: 'AvenirNextGeorgian-Regular';
}
.font-base-italic {
  font-family: 'AvenirNextGeorgian-Regular';
}
.font-italic-medium {
  font-family: 'AvenirNextGeorgian-Regular';
}
.text-normal {
    font-weight:normal;
    font-style: normal;
}
.arctic-bold {
  font-family: 'AvenirNextGeorgian-Regular';
}
.arctic-headline {
  font-family: 'AvenirNextGeorgian-Regular';
}

* {
  font-family: 'AvenirNextGeorgian-Regular';
}


.line-through {
    text-decoration: line-through;
}
.is-uppercase {
    text-transform: uppercase;
    -moz-font-feature-settings: 'case';
    -webkit-font-feature-settings: 'case';
    font-feature-settings: 'case' on;
}

.lari {
    -moz-font-feature-settings: normal;
    -moz-font-language-override: normal;
    display: inline-block;
    font-family: Lari !important;
    font-size: inherit;
    font-size-adjust: none;
    font-stretch: normal;
    font-style: normal;
    font-variant: normal;
    /* font-weight: normal;*/
    line-height: 1;
    text-rendering: auto;
}

