@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $value in $z-index {
      .z-index#{$infix}-#{$value} {
        z-index: $value;
      }
    }
  }
}

/*transforms*/

.transform-y-center {
  transform: translateY(-50%);
}
.absolute--right-center-position {
  top:50%;
  transform: translateY(-50%);
  right:0;
  &.transform-right-top {
    transform: translate(100%,-50%);
  }
}
.absolute--left-top-position {
  top:0;
  left:0;
  &.transform--100-0 {
    transform: translate(-100%,0);
  }
}
.absolute--right-bottom-position {
  bottom: 0;
  right: 0;
  &.transform--50px-100 {
    transform: translate(50px,100%);
  }
}
.transform-center {
  transform: translate(-50%,-50%);
}
.transform-center-rev {
  transform: translate(50%,-50%);
}
.absolute--right-top-position {
  top:0;
  right:0;
  &.transform-right-top {
    transform: translate(100%,-50%);
  }
  &.absolute--transform-100-50 {
    transform: translate(100%,-50%);
  }
  &.absolute--transform-50 {
    transform: translate(50%,0);
  }
  &.absolute--transform-x {
    right:15px;
    transform: translateX(100%);
    padding-left: 15px;
    box-sizing: content-box;
    background-color: white;
  }
}
.absolute--left-center-position {
  top:50%;
  transform: translateY(-50%);
  left:0;
}
.absolute--right-center-position {
  top:50%;
  transform: translateY(-50%);
  right:0;
}
.absolute--center-position {
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
}
.absolute--center-bottom-position {
  bottom:0;
  left:50%;
  transform: translate(-50%,0);
  &.transform--50-100 {
    transform: translate(-50%,100%);
  }
}
.absolute--center-top-position {
  top:0;
  left:50%;
  transform: translate(-50%,0);
  &.transform--50-100 {
    transform: translate(-50%,100%);
  }
  &.transform-n50-n100 {
    transform: translate(-50%,-100%);
  }
}
/*rotates*/
.rotate {
  transform: rotate(0deg);
  transition: $transition-all;
  &.rotate-up {
    transform: rotate(180deg);
  }
  &.rotate-x {
    transform: rotate(45deg);
  }
}
.rotate-vertical {
  transform: rotateX(-180deg);
}
/*Width/Height/Min-Width/Min-Height*/
$dimensions:(
  0,3px,5px,8px,10px,13px,15px,18px,20px,21px,22px,25px,30px,33px,40px,46px,48px,50px,53px,56px,60px,73px,80px,85px,86px,93px,95px,99px,100px,120px,128px,150px,160px,170px,180px,210px,260px,320px,410px,460px,520px,580px,640px,750px,950px,
);

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $prop,$abbrev in (height:h, width:w, flex:w-basis, min-height:min-height, max-height:max-height, min-width:min-width,max-width:max-width,top:top,left:left,right:right,bottom:bottom) {
      @each $value in $dimensions {
        .#{$abbrev}#{$infix}-#{$value} {
          @if $prop==flex {
            width: $value !important;
            #{$prop}: 0 0 $value !important;
          } @else {
            #{$prop}: $value !important;
          }
        }
      }
    }
    @each $abbrev,$prop in (height:initial, width:initial, min-height:auto, max-height:auto, min-width:auto,max-width:auto) {
      .#{$abbrev}#{$infix}-#{$prop} {
        #{$abbrev}: $prop !important;
      }
    }

  }
}

$step    : 1;
$from    : ceil(1/$step);
$through : ceil(95/$step);

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $prop,$abbrev in (height:h, width:w, flex:w-basis, top:top,left:left,right:right,bottom:bottom) {

      @for $i from $from through $through {
        .#{$abbrev}#{$infix}-#{$i} {
          @if $prop==flex {
            width: $i*1% !important;
            #{$prop}: 0 0 $i*1% !important;
          } @else {
            #{$prop}: $i*1% !important;
          }
        }
      }
    }
  }
}
