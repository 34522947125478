.container-admin {
    max-width: 1700px;
    margin: 0 auto;
}

.admin-sedebar {
    box-shadow: 0 0.46875rem 2.1875rem rgb(31 10 6 / 3%), 0 0.9375rem 1.40625rem rgb(31 10 6 / 3%), 0 0.25rem 0.53125rem rgb(31 10 6 / 5%), 0 0.125rem 0.1875rem rgb(31 10 6 / 3%);
    position: sticky;
    top: 200px;
}
