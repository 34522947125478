.scrollbar {
    overflow: auto;
}
.scrollbar::-webkit-scrollbar-track {
    webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0);
    background-color: rgba(18, 190, 233, 0.2);
    border-radius: 3px;
}
.scrollbar::-webkit-scrollbar {
    width: 5px;
    opacity: 0.1;
    background-color: rgba(18, 190, 233, 0.2);
}   
.scrollbar::-webkit-scrollbar-thumb {
    border-radius: 3px;
    webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0);
    background-color: rgb(18, 190, 233);
}