.dropdown-custom {
	.dropdown-menu {
		border-radius: 10px;
		box-shadow: 0 2px 6px 0 rgba(39, 42, 55, 0.1);
		border: solid 1px #e4e7ed;
		-webkit-transform: translateY(5px);
		-moz-transform: translateY(5px);
		transform: translateY(5px);
		display: block;
		opacity: 0;
    	visibility: hidden;
    	// -webkit-transition: all .2s ease-out;
	    // -moz-transition: all .2s ease-out;
	    // transition: all .2s ease-out;
		&.show {
		    -webkit-transform: translateY(0);
		    -moz-transform: translateY(0);
		    transform: translateY(0);
		    opacity: 1;
		    visibility: visible;
		}
		.dropdown-item {
			font-size: 14px;
			color: #272a37;
			&:hover, &:focus {
				background-color: rgba($color: #d7dce4, $alpha: .2);
		    }
		    &.active {
		    	color: #4a6cfa;
		    }
		    &.active {
				background-color: rgba($color: #d7dce4, $alpha: .2);
		    	color: #4a6cfa;
		    }
		}
	}
}

	.dots-dropdown {
		.dropdown-toggle {
			padding: 18px;
			border-radius: 50%;
			&::after{
				display: none;
			}
			// background-image: url('../../../img/icons/dots-i.svg');
			background-position: center center;
			background-repeat: no-repeat;
		
		}
		.dropdown-custom {
			&.show {
					.dropdown-toggle {
						background-color: #f1f3f6;
					}
			}
		}
	}


