
.custom-scroll-bar {
  /* width */
  &::-webkit-scrollbar {
    width: 2px;
    border-radius: 2px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: $white;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $gray-400;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $abbrev in (overflow-x, overflow-y) {
      @each $value in $overflows {
        .#{$abbrev}#{$infix}-#{$value} {
          #{$abbrev}: $value !important;
        }
      }
    }
  }
}
