.home-category-drop-shadow {
  box-shadow: 0 30px 90px #00000014;
}
.fade-out {
  opacity: 0;
  @include transition($transition-fade);
}
.fade-in {
  opacity: 1;
  @include transition($transition-fade);
}
.fade-out-hover {
  opacity: $opacity-100;
  @include transition($transition-fade);
  &:hover {
    opacity: $opacity-80;
  }
}
.fade-in-hover {
  opacity: $opacity-80;
  @include transition($transition-fade);
  &:hover {
    opacity: $opacity-100 !important;
  }
}
/*targeted-hover*/
.hover-parent {
  .hover-lg-target,
  .hover-target {
    opacity: 0;
    pointer-events: none;
    transition: $transition-all;
    &.no-transition {
      transition: none;
    }
  }
  .hover-bg-white-gray {
    background-color: $white;
    transition: $transition-all;
  }
  &.hover--shadow {
    &::before {
      content:'';
      position: absolute;
      left:0;
      top:0;
      height:100%;
      width: 100%;
      background-color: $white;
      opacity: 0;
      pointer-events: none;
      box-shadow: 0 20px 20px #0000000D;
    }
  }
  &:hover {
    z-index: 2;
    &::before {
      opacity: 1;
    }
    .hover-target {
      opacity: 1;
      pointer-events: auto;
    }
    .hover-lg-target {
      opacity: 1;
      pointer-events: auto;
    }
  }
}
/*hovers*/
@each $color, $value in $colors {
  @include path-fill(".path-fill-#{$color}", $value);
}

@include media-breakpoint-down(m) {
  /*targeted-hover*/
  .hover-parent {
    .hover-lg-target {
      opacity: 1;
      pointer-events: auto;
    }
    &.hover--shadow {
      &::before {
        display: none;
      }
    }
  }
}
