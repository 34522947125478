@keyframes content-placeholder-animation {
  0% {
    transform: translateX(-50%);
  }

  100% {
    transform: translateX(300%);
  }
}
/**/
@keyframes shadow-animate-left {
  0% {
    transform: rotate(0deg) translate(0%, 0%);
  }

  100% {
    transform: rotate(-20deg) translate(-30%, 0%);
  }
}
@keyframes shadow-animate-right {
  0% {
    transform: rotate(0deg) translate(0%, 0%);
  }

  100% {
    transform: rotate(20deg) translate(30%, 0%);
  }
}
/*spinning*/
@keyframes spinning {
  0% {
    transform: scale(1) translateY(0);
  }
  10% {
    transform: scale(1.2, 0.6);
  }
  30% {
    transform: scale(0.8, 1.1) translateY(-50%);
  }
  50% {
    transform: scale(1) translateY(0);
  }
  100% {
    transform: translateY(0);
  }
}
