.btn {
    box-shadow: none !important;
}
input {
    box-shadow: none !important;
    outline: none !important;
    border-color: rgba($color: #000000, $alpha: .3) !important;

    &:focus {
        border-color: rgba($color: #000000, $alpha: 1) !important;
    }
}
.border-radius {
	border-radius: $border-radius;
}
.border-radius-sm {
	border-radius: $border-radius-sm;
}
.border-radius-md {
	border-radius: $border-radius-md;
}
.border-radius-lg {
	border-radius: $border-radius-lg;
}
.border-6px {
    border-width: 6px !important;
}
.flex-shrink {
    flex-shrink: 0;
}
.flex-1 {
    flex: 1;
}
.clamp {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;  
    overflow: hidden;
    &._2 {
        -webkit-line-clamp: 2;
    }
    &._3 {
        -webkit-line-clamp: 3;
    }
    &._4 {
        -webkit-line-clamp: 4;
    }

}

.object-fit-contain {
    object-fit: contain;
}
footer {
    -webkit-box-shadow: 0px -2px 14px 0px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px -2px 14px 0px rgba(0,0,0,0.25);
    box-shadow: 0px -2px 14px 0px rgba(0,0,0,0.25);
}

body {
    overflow-x: hidden;
}

.container {
    width: 100%;
    max-width: 1920px;
    padding: 0;
    @media (max-width:1405px) {
        max-width: 1100px;
    }
    @media (max-width:1199px) {
        max-width: 100%;
        padding: 0 48px;
    }
    @media (max-width:1023px) {
        max-width: 100%;
        padding: 0 20px;
    }
}
#globalContainer {
    overflow: hidden;
    background-color:#8e7fbb ;
}
header {
    background-color: #8e7fbb;
    max-width: 1920px;
    border-bottom: 1px solid rgba($color:  $main-title, $alpha: .3);
    &.home {
        position: fixed;
        top:0;
        left: 0;
        width: 100%;
        z-index: 9999999;
        background-color: transparent;
        border: none;
    }
    @media (max-width:767px) {
        height: 89px;
        background-color: #8e7fbb;
        border-bottom: 1px solid white;
    }
}
.main-section {
    height: 100vh;
    @media (max-width:767px) {
       padding-top: 89px;
    }
}
.container {
    max-width: 1400px;
    margin: 0 auto;
}
.object-fit-cover {
    object-fit: cover;
}
.courses-box {
    padding-top: 79%;
    overflow: hidden;
    img {
        transition: .5s ease-in-out;
    }
    &:hover {
        img {
            transform: scale(1.08);
        }
    }
}
main {
    // padding-top: 100px;
    background-color: #8e7fbb;
   
}
header {
    ul {
        li {
             a {
                 transition: .5s ease-in-out;
                 padding-bottom: 4px;
                 position: relative;
                 &::after {
                     content: '';
                     position: absolute;
                     bottom: 0;
                     left: 0;
                     width: 100%;
                     height: 1px;
                     background-color: $main-title;
                     transform: scaleX(0);
                     transform-origin: center;
                     transition: .5s ease-in-out;
                 }
                 &:hover {
                     color: $main-title !important;
                     &::after {
                         transform: scaleX(1);
                     }
                 }
             }
        }
    }
}
.fragment {
    width: 114px;
    position: absolute;
    right: -4px;
    top: -36px;
}
.card-logo {
    position: absolute;
    top: 24px;
    left: 24px;
    width: 130px;
    img {
        width: 100%;
        object-fit: contain;
    }
}

.form-box {
    background-color: rgba($color: #8972cf, $alpha: .9);
    input {
        height: 46px;
    }
}



.custom-control-label {
    &::before {
        border-color: $main-title !important;
        background-color: $main-title !important;
    }
}
.custom-checkbox {
    .custom-control-label  {
        &::before {
            border-radius: 0;
           }
    }
 
}

.logo-bottom {
    width: 200px;
    position: absolute;
    bottom: 48px;
    left: 50%;
    transform: translateX(-50%);
}
.single-course-img  {
    padding-top:60%;
    position: relative;

}
.btn-burger {
    width: 36px;
    height: 36px;
    position: absolute;
    right: 24px;
    top: 24px;
}
.header-menu-list {
    @media (max-width:767px) {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        flex-direction: column;
        align-items: flex-start !important;
        padding-top: 100px;
        background-color: #8972cf;
        right: -100%;
        z-index: 100;
        transition: .5s ease-in-out;
        li {
            margin-bottom: 24px;
        }
    }
}
.btn-burger-close {
    position: absolute;
    top: 24px;
    right: 24px;
}
#globalContainer {
    &.menu-open {
        .header-menu-list {
            right: 0;
        }
    }
}
.single-course-media-box  {
    div {
        width: 100% !important;
    }
    iframe {
        width: 100%;
    }
}
.z-index-n1 {
    z-index: -1;
}
.course-link {
    .arrow {
        transition: .5s ease-in-out;
    }
    &:hover {
        color: #fff !important;
        .arrow {
            transform: translateX(10px);
        }
    }
}

.rdw-editor-wrapper {
    background-color: #fff;
}
.site-info {
    width: 100%;
    max-width: 1920px;
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 24px;
    z-index: 10;
}
.single-course-text  {
    h1 ,h2, h3  {
        color: $main-title;
    } 
    h4, h5  {
     color: $main-title;
 }
 }

.swiper-pagination {
    width: 100%;
    height: 20px;
    position: absolute;
    bottom: 32px;
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
    .swiper-pagination-bullet {
        width: 6px;
        height: 6px;
        display: flex;
        margin: 0 6px;
        border-radius: 50%;
        background-color: #f7912d;
        &.swiper-pagination-bullet-active {
            width: 12px;
            height: 12px;
        }
    }
}
.cookie-box {
    position: fixed;
    bottom: 24px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 120;

}