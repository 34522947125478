.btn {
	border: 0;
	border-radius: $border-radius;
	font-size: 12px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
	text-align: center;
	padding: 12px 28px;
	&-lg {
		border-radius: $border-radius-lg;
		padding: 19px 32px 20px;
		font-size: 14px;
		font-weight: normal;
	}
	&-danger {
		background-image: linear-gradient(70deg, #ef2d56, #f25777);
		&:hover {
			background: #ef2d56;
		}
	}
	&-light {
		background-color: #f1f3f6;
	}
	&-success {
		background-image: linear-gradient(75deg, #4cd964, #73e186 100%);
	}
	&-blue {
		background-image: linear-gradient(76deg, #4a6cfa, #728cfb);
	}
	&-blue {
		background-image: linear-gradient(72deg, var(--bluey-grey), #96a1b7);
	}
	&-orange {
		background-image: linear-gradient(45deg, #ff641f, #ff8650);
	}
	&-icon {
		width: 42px;
		height: 42px;
		padding: 0;
		align-items: center;
		&.btn-lg {
			width: 60px;
			height: 60px;
		}
	}
	&-empty {
		border: 0;
		background: none;
        display: flex;
        align-items: center;
        justify-content: center;
        outline: none;
        &:hover {
        	background-color: #f4f4f7;
        }
	}
}