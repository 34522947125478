$black:#000000;
$white:#ffff;
$gray-light:#797878;
$main-blue:#5d4b9f;
$main-title:#f7912d;

/*theme-colors Merge Or overrides*/
$theme-colors: () !default;
$theme-colors: map-merge(
                (
                'black':$black,
                'white': $white,
                'gray-light': $gray-light,
                'main-blue': $main-blue,
                'main-title': $main-title,
                ),
                $theme-colors
);
/*colors Merge Or overrides*/
/*$colors: () !default;
$colors: map-merge(
    (
      'white': $white,
    ),
    $colors
);*/
/*RGBA backgrounds*/

$rgba-colors: () !default;
$bg-gradient: linear-gradient(76deg, #fec900, #ffd026);
$button-bg-gradient: linear-gradient(80deg, #fec900, #ffd026);
$rgba-colors: map-merge(
                (
                //       
                ),
                $rgba-colors
);
/*opacity*/
$opacity-80: 0.8;
$opacity-70: 0.7;
$opacity-60: 0.6;
$opacity-50: 0.5;
$opacity-25: 0.25;
$opacity-20: 0.2;
$opacity-15: 0.15;
$opacity-10: 0.1;
$opacity-0: 0;
$opacity: () !default;
$opacity: map-merge(
                (
                        '80':  $opacity-80,
                        '70':  $opacity-70,
                        '60':  $opacity-60,
                        '50':  $opacity-50,
                        '25':  $opacity-25,
                        '20':  $opacity-20,
                        '15':  $opacity-15,
                        '10':  $opacity-10,
                        '0':  $opacity-0,
                ),
                $opacity
);
/*spacer*/
$spacer: 1rem;
$spacers: ();
$spacers: map-merge(
                (
                        0:    0,
                        1px:  1px,
                        2px:  2px,
                        3px:  3px,
                        4px:  4px,
                        5px:  5px,
                        6px:  6px,
                        8px:  8px,
                        10px:  10px,
                        11px:  11px,
                        12px:  12px,
                        13px:  13px,
                        14px:  14px,
                        15px:  15px,
                        16px:  16px,
                        18px:  18px,
                        20px:  20px,
                        21px:  21px,
                        23px:  23px,
                        24px:  24px,
                        25px:  25px,
                        26px:  26px,
                        28px:  28px,
                        30px:  30px,
                        32px:  32px,
                        34px:  34px,
                        36px:  36px,
                        40px:  40px,
                        48px: 48px,
                        52px: 52px,
                        56px: 56px,
                        64px: 64px,
                        60px: 60px,
                        68px: 68px,
                        72px: 72px,
                        80px: 80px,
                        84px: 84px,
                        88px: 88px,
                        100px: 100px,
                        108px: 108px,
                        110px: 110px,
                        116px: 116px,
                        120px: 120px,
                        128px: 128px,
                        130px: 130px,
                        140px: 140px,
                        150px: 150px,
                        160px: 160px,
                        170px: 170px,
                        200px: 200px,
                        210px: 210px,
                        225px: 225px,
                        240px: 240px,
                        250px: 250px,
                ),
                $spacers
);
/*borders-width*/
$borders-width: ();
$borders-width: map-merge(
                (
                        0: 0,
                        1: 1px,
                        2: 2px,
                        3: 3px,
                        10: 10px,
                ),
                $borders-width
);
$border-radius-10:       10px;
// 
$border-radius:       8px;
$border-radius-xs:    6px;
$border-radius-st:    10px;
$border-radius-sm:    12px;
$border-radius-md:    16px;
$border-radius-lg:    24px;
$border-radius-xl:    26px;
$border-radius-sizes: ();
$border-radius-sizes: map-merge(
  (
          0: 0,
          6: $border-radius-xs,
          8: $border-radius,
          10: $border-radius-st,
          12: $border-radius-sm,
          16: $border-radius-md,
          24: $border-radius-lg,
          26: $border-radius-xl
  ),
  $border-radius-sizes
);

/*fonts*/
$font-family-base: 'Helvetica Neue LT GEO 55 Roman';
$font-family-base-haevy: 'Helvetica Neue LT GEO 85 Heavy';
$font-family-base-caps: 'Helvetica Neue LT GEO 55 Roman';
$firago: 'FiraGO-Italic';
$font-family-medium: 'Helvetica Neue LT GEO 65 Medium';
$font-family-medium-caps:'Helvetica Neue LT GEO 65 Medium';

$font-family-bold: 'Helvetica Neue LT GEO 75 Bold';
$font-family-bold-caps: 'Helvetica Neue LT GEO 75 Bold';
$font-black: 'Helvetica Neue LT GEO 95 Black';
$font-family-lari: 'Lari';
$firago-medium: 'FiraGO-MediumItalic';
$arctic-bold: 'ArcticFox-Bold';
$arctic-headline: 'ArcticFox-Headline';
/*Font Size*/
$font-6:  6px;
$font-10: 10px;
$font-11: 11px;
$font-12: 12px;
$font-13: 13px;
$font-14: 14px;
$font-15: 15px;
$font-16: 16px;
$font-18: 18px;
$font-21: 21px;
$font-23: 23px;
$font-24: 24px;
$font-26: 26px;
$font-28: 28px;
$font-30: 30px;
$font-32: 32px;
$font-34: 34px;
$font-36: 36px;
$font-38: 38px;
$font-45: 45px;
$font-48: 48px;
$font-64: 64px;
$font-72: 72px;
$font-96:96px;
$font-144: 144px;
$font-167: 167px;
/*$drop-shadpw*/
$drop-shadpw: 0 30px 60px #0000000F;
/*Hover opacity variables*/
$opacity-100: 1;
$opacity-80: 0.8;
/*$transition-fill*/
$transition-time : .15s;
$transition-time-slow : .5s;
$transition-fill:  fill $transition-time ease-in-out;
/*$transition-bg-fill*/
$transition-bg:  background $transition-time ease-in-out;
/*$transition-all*/
$transition-all:  all $transition-time ease-in-out;
$transition-all-slow:  all $transition-time-slow ease-in-out;
/*default transitions*/
$border-color-transition: border .15s ease-in-out;
$text-color-transition: color .15s ease-in-out;
$emphasized-link-hover-darken-percentage : 0;
/*Header*/
// $link-color:                              $blue;
//$link-decoration:                         none;
//$link-hover-color:                        darken($link-color, 0%);
//$link-hover-decoration:                   underline;

/*sizes*/
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-m: 1024px;
$breakpoint-lg: 1200px;
$breakpoint-xl: 1600px;

/*grid-breakpoints*/
$grid-breakpoints: (
        xs: 0,
        sm: $breakpoint-sm,
        md: $breakpoint-md,
        m: $breakpoint-m,
        lg: $breakpoint-lg,
        xl: $breakpoint-xl
);
$grid-breakpoints-rev: (
        xs: 576px,
        sm: 768px,
        m: 1024px,
        md: 1200px,
        lg: 1600px,
);
/*$container-max-widths*/
$container-max-widths: (
  /*  sm: 576px,
    md: 768px,*/
        lg: 1200px,
        xl: 1302px
);
/*$overflows*/
$overflows: (
        auto,
        initial,
        hidden,
        scroll,
);
/*z-index*/
$z-index: (
        1,
        2,
        3,
        9,
        1000,
);

