.rounded-md {
  border-radius: $border-radius-md;
}
.rounded-lg-bottom {
  border-bottom-right-radius: $border-radius-lg !important;
  border-bottom-left-radius: $border-radius-lg !important;
}
.rounded-lg-right {
  border-top-right-radius: $border-radius-lg !important;
  border-bottom-right-radius: $border-radius-lg !important;
}
.rounded-lg-top {
  border-top-left-radius: $border-radius-lg !important;
  border-top-right-radius: $border-radius-lg !important;
}
.rounded-tl-br {
  border-bottom-right-radius: $border-radius !important;
  border-top-left-radius: $border-radius !important;
}
.rounded-bl-lg {
  border-bottom-left-radius: $border-radius-lg !important;
}
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $name, $value in $borders-width {
      @include border-width(".border-solid#{$infix}-#{$name}", $value, solid);
    }
    @each $name, $value in $borders-width {
      @include border-width(".border-dashed#{$infix}-#{$name}", $value, dashed);
    }
    @each $name, $value in $border-radius-sizes {
      @include border-radius(".border-radius#{$infix}-#{$name}", $value);
    }
  }
}