.simple-pagination  {
    display: flex;
    align-items: center;
    height: 44px;
    .pagination-item {
        display: inline-flex !important;
        background: $white;
        display: flex;
        align-items: center;
        height: 44px;
        a {
            min-width: 24px;
            height: 24px;
            font-family: $font-family-medium;
            font-size: 12px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #8996ae;
            outline: none;
            border:none;
            cursor: pointer;
            margin:0 4px;
            padding: 0 6px;
           }
           &.selected {
              
            a {
                background-color: $black;
                color: white;

            }
        }
      
        
         a {
            &:hover {
                background-color: $black;
                color: white !important;
            }
            

         }
    
        &:nth-child(2) {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
            padding-left: 16px;
        }
        &:nth-last-child(2) {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
            padding-right: 24px;
        }
        &:first-child {
            margin-right: 8px;
            width: 44px;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
          
            a {
                width: 100%;
                height: 100%;
                cursor: pointer;
                background-image:  url('../../../img/icons/pag-left.svg');
                background-repeat: no-repeat;
                background-size: 6.621px 10.243px;
                background-position: center;
                &:hover {
                    background-color:transparent;
                }
            }
            &:hover {
               a {
                background-color: transparent!important;
               }
               }
        }
        &:last-child {
            margin-left: 8px;
            width: 44px;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
           
            a {
                width: 100%;
                height: 100%;
                cursor: pointer;
                background-image:  url('../../../img/icons/pag-right.svg');
                background-repeat: no-repeat;
                background-size: 6.621px 10.243px;
                background-position: center;
                &:hover {
                    background-color:transparent;
                }
            }
            &:hover {
                a {
                 background-color: transparent!important;
                }
                }
        }
    }
}
@media (min-width: 1679px) {
    .pagination-row {
        width: 810px;
    }
}


