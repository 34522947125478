@each $color, $value in $rgba-colors {
  @include rgba-bg-variant(".rgba-bg-#{$color}", $value);
}
.gradient-bg-before {
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left:0;
    top:0;
    background: transparent linear-gradient(269deg, #FFFFFF00 0%, #7B06FF26 100%) 0% 0% no-repeat padding-box;
    opacity: 0.56;
    margin-left: -30px;
  }
}
